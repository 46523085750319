import { AiOutlineGithub } from "react-icons/ai";
import { CgWebsite } from "react-icons/cg";

import classes from "./AppDetails.module.css";
import newstagappImage from "../assets/images/newstagapp.png";

const NewsTagApp = () => {
  window.scrollTo(0, 0);

  return (
    <>
      <div className={classes.projectTitle}>NewsTagApp</div>
      <div className={classes.projectContainer}>
        <div className={classes.projectImageBox}>
          <img
            className={classes.projectImage}
            src={newstagappImage}
            alt='newstagapp image'
          />
        </div>
        <div className={classes.projectURL}>
          <a
            href='https://github.com/k-e-r/NewsTagApp'
            target='_blank'
            rel='noopener noreferrer'
          >
            CODE <AiOutlineGithub />
          </a>
          <a
            href='https://www.kestudies.com/categories/breaking-news/us'
            target='_blank'
            rel='noopener noreferrer'
          >
            WEBSITE <CgWebsite />
          </a>
        </div>
        <div className={classes.where}>
          <div className={classes.whereTitle}>
            Where is the most striking part of this app?
          </div>
          <p className={classes.whereDescription}>
            As you know, free API has the limit of the number being called. This
            application uses a free news api. If it cannot call API without any
            measures, this website cannot show you anything. I would like to
            avoid this situation. Therefore, I use the server as a backup. If
            anyone accesses this website, this application checks the timestamp
            of articles of news from the server and this website decides to call
            the API depending on its timestamp.
          </p>
          <p className={classes.whereDescription}>
            In other words, even if anyone accesses this website repeatedly and
            quickly, this website does not call API each access.
          </p>
          <p className={classes.whereDescription}>
            Also, if this website cannot connect to the server under any
            problems, this website can call the API as a failsafe. If this
            website must avoid displaying absolutely nothing, I think it should
            operate with multiple servers, but in this case it is not that
            critical, so if the server goes down and gets stuck on the API call
            limit, only the menu will be available for checking. ;)
          </p>
        </div>
        <div className={classes.why}>
          <div className={classes.whyTitle}>Why did I make this app?</div>
          <p className={classes.whyDescription}>
            I wanted something that can be managed on the App side, since I can
            no longer organize news bookmarks in my browser.
          </p>
        </div>
        <div className={classes.who}>
          <div className={classes.whoTitle}>
            Who are the targets of this app?
          </div>
          <p className={classes.whoDescription}>
            Business people interested in world news. Therefore, I built a
            website with a low color palette.
          </p>
        </div>
        <div className={classes.what}>
          <div className={classes.whatTitle}>
            What is the thing of this website that I struggled with the most?
          </div>
          <p className={classes.whatDescription}>
            I think this website's functions are so simple. Therefore, I thought
            that making this website must be easy... But I was suffering from
            the cache of images. Sometimes JavaScript's event such as "onLoad"
            did not work as well as I think due to cache of images. If I did not
            use "Loding images" during loading image, I would not be suffering
            from this problem. But I wanted to use this images from a UX points.
            At first, I could not find the reason for the cache. So I had been
            suffering from it for a while...
          </p>
        </div>
      </div>
    </>
  );
};

export default NewsTagApp;
