import { Link } from "react-router-dom";

import classes from "./ProjectCard.module.css";
import newstagappImage from "../../../assets/images/newstagapp.png";
import autoschedulerImage from "../../../assets/images/autoscheduler.png";
import timetrackerImage from "../../../assets/images/timetracker.png";
import hodieImage from "../../../assets/images/hodie.png";
import coeusImage from "../../../assets/images/coeus.png";
import bookinfoImage from "../../../assets/images/bookinfo.png";

const ProjectCard = () => {
  return (
    <>
      <Link to='/newstagapp' className={classes.projectLink}>
        <div className={classes.projectCard}>
          <div className={classes.projectTitle}>NewsTagApp</div>
          <div className={classes.projectSkillBox}>
            <div className={classes.projectSkill}>Personal Project</div>
            <div className={classes.projectSkill}>React</div>
            <div className={classes.projectSkill}>Redux</div>
            <div className={classes.projectSkill}>Firebase</div>
            <div className={classes.projectSkill}>REST API</div>
            <div className={classes.projectSkill}>Authentification</div>
            <div className={classes.projectSkill}>Loading Skeleton</div>
          </div>
          <div className={classes.projectBox}>
            <div className={classes.projectImageBox}>
              <img
                className={classes.projectImage}
                src={newstagappImage}
                alt='newstagapp image'
              />
            </div>
            <div className={classes.projectExplanation}>
              <p>
                This is a web app that you can check some news in each country,
                and what's more add bookmark the article you like after sign in!
              </p>
            </div>
          </div>
        </div>
      </Link>

      <hr className={classes.hrDesign} />

      <Link to='/autoscheduler' className={classes.projectLink}>
        <div className={classes.projectCard}>
          <div className={classes.projectTitle}>AutoScheduler</div>
          <div className={classes.projectSkillBox}>
            <div className={classes.projectSkill}>Personal Project</div>
            <div className={classes.projectSkill}>React</div>
            <div className={classes.projectSkill}>Node.js</div>
            <div className={classes.projectSkill}>MongoDB</div>
            <div className={classes.projectSkill}>Mongoose</div>
            <div className={classes.projectSkill}>Authentification</div>
            <div className={classes.projectSkill}>Heroku</div>
          </div>
          <div className={classes.projectBox}>
            <div className={classes.projectImageBox}>
              <img
                className={classes.projectImage}
                src={autoschedulerImage}
                alt='autoscheduler image'
              />
            </div>
            <div className={classes.projectExplanation}>
              <p>
                This is a web app that you can set the schedule of the task you
                have to do, and what's more automatically add some schedule for
                remembering along the forgetting curve.
              </p>
            </div>
          </div>
        </div>
      </Link>

      <hr className={classes.hrDesign} />

      <Link to='/timetracker' className={classes.projectLink}>
        <div className={classes.projectCard}>
          <div className={classes.projectTitle}>TimeTracker</div>
          <div className={classes.projectSkillBox}>
            <div className={classes.projectSkill}>Group Project</div>
            <div className={classes.projectSkill}>React</div>
            <div className={classes.projectSkill}>Node.js</div>
            <div className={classes.projectSkill}>MongoDB</div>
            <div className={classes.projectSkill}>Mongoose</div>
            <div className={classes.projectSkill}>Authentification</div>
            <div className={classes.projectSkill}>Heroku</div>
          </div>
          <div className={classes.projectBox}>
            <div className={classes.projectImageBox}>
              <img
                className={classes.projectImage}
                src={timetrackerImage}
                alt='timetracker image'
              />
            </div>
            <div className={classes.projectExplanation}>
              <p>
                This is a web app that you can log your working time. It works
                as a normal timer and a Pomodoro timer. Basically, they are
                organized within a week.
              </p>
            </div>
          </div>
        </div>
      </Link>

      <hr className={classes.hrDesign} />

      <Link to='/hodie' className={classes.projectLink}>
        <div className={classes.projectCard}>
          <div className={classes.projectTitle}>HODIE (an e-commerce site)</div>
          <div className={classes.projectSkillBox}>
            <div className={classes.projectSkill}>Personal Project</div>
            <div className={classes.projectSkill}>HTML</div>
            <div className={classes.projectSkill}>JavaScript</div>
            <div className={classes.projectSkill}>Sass</div>
          </div>
          <div className={classes.projectBox}>
            <div className={classes.projectImageBox}>
              <img
                className={classes.projectImage}
                src={hodieImage}
                alt='hodie image'
              />
            </div>
            <div className={classes.projectExplanation}>
              <p>This is an e-commerce web app for testing cart function.</p>
            </div>
          </div>
        </div>
      </Link>

      <hr className={classes.hrDesign} />

      <Link to='/coeus' className={classes.projectLink}>
        <div className={classes.projectCard}>
          <div className={classes.projectTitle}>COEUS</div>
          <div className={classes.projectSkillBox}>
            <div className={classes.projectSkill}>Group Project</div>
            <div className={classes.projectSkill}>HTML</div>
            <div className={classes.projectSkill}>JavaScript</div>
            <div className={classes.projectSkill}>CSS</div>
            <div className={classes.projectSkill}>Haiku Animation</div>
            <div className={classes.projectSkill}>PHP</div>
          </div>
          <div className={classes.projectBox}>
            <div className={classes.projectImageBox}>
              <img
                className={classes.projectImage}
                src={coeusImage}
                alt='coeus image'
              />
            </div>
            <div className={classes.projectExplanation}>
              <p>This is a website for a company I made with a team.</p>
            </div>
          </div>
        </div>
      </Link>

      <hr className={classes.hrDesign} />

      <Link to='/bookinfo' className={classes.projectLink}>
        <div className={classes.projectCard}>
          <div className={classes.projectTitle}>Book Info</div>
          <div className={classes.projectSkillBox}>
            <div className={classes.projectSkill}>Personal Project</div>
            <div className={classes.projectSkill}>HTML</div>
            <div className={classes.projectSkill}>JavaScript</div>
            <div className={classes.projectSkill}>CSS</div>
            <div className={classes.projectSkill}>REST API</div>
          </div>
          <div className={classes.projectBox}>
            <div className={classes.projectImageBox}>
              <img
                className={classes.projectImage}
                src={bookinfoImage}
                alt='bookinfo image'
              />
            </div>
            <div className={classes.projectExplanation}>
              <p>
                You can search anime, book and game with this demo site and you
                can purchase via it.
              </p>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ProjectCard;
