import { AiOutlineHtml5, AiOutlineGithub } from "react-icons/ai";
import { DiCss3, DiSass, DiReact } from "react-icons/di";
import { IoLogoJavascript } from "react-icons/io5";
import { FaNode, FaBootstrap, FaWordpress } from "react-icons/fa";
import {
  SiMongodb,
  SiHeroku,
  SiFirebase,
  SiGit,
  SiRedux,
  SiBitbucket,
  SiEspressif,
  SiArduino,
  SiExpress,
  SiSpring,
  SiPostgresql,
  SiMysql,
  SiAngular,
  SiLinux,
} from "react-icons/si";
import { BiMicrochip } from "react-icons/bi";
import { GiSolderingIron } from "react-icons/gi";

import classes from "./SkillBox.module.css";

const SkillBox = () => {
  return (
    <div id="skills" className={classes.skillContainer}>
      <div className={classes.skillTitle}>SKILLS</div>
      <div className={classes.skillSubTitle}>FRAMEWORK / LIBRARIES</div>
      <div className={classes.skillBox}>
        <div className={classes.skillItem}>
          <DiReact />
          <p>React</p>
        </div>
        <div className={classes.skillItem}>
          <SiRedux />
          <p>Redux</p>
        </div>
        <div className={classes.skillItem}>
          <SiAngular />
          <p>Angular</p>
        </div>
        <div className={classes.skillItem}>
          <FaBootstrap />
          <p>Bootstrap</p>
        </div>
        <div className={classes.skillItem}>
          <SiExpress />
          <p>Express</p>
        </div>
        <div className={classes.skillItem}>
          <SiSpring />
          <p>Spring Framework</p>
        </div>
      </div>
      <div className={classes.skillSubTitle}>LANGUAGE</div>
      <div className={classes.skillBox}>
        <div className={classes.skillItem}>
          <IoLogoJavascript />
          <p>JavaScript</p>
        </div>
        <div className={classes.skillItem}>
          <FaNode />
          <p>Node</p>
        </div>
        <div className={classes.skillItem}>
          <AiOutlineHtml5 />
          <p>HTML</p>
        </div>
        <div className={classes.skillItem}>
          <DiCss3 />
          <p>CSS</p>
        </div>
        <div className={classes.skillItem}>
          <DiSass />
          <p>Sass</p>
        </div>
      </div>
      <div className={classes.skillSubTitle}>DATABASE</div>
      <div className={classes.skillBox}>
        <div className={classes.skillItem}>
          <SiMongodb />
          <p>Mongodb</p>
        </div>
        <div className={classes.skillItem}>
          <SiPostgresql />
          <p>PostgreSQL</p>
        </div>
        <div className={classes.skillItem}>
          <SiMysql />
          <p>MySQL</p>
        </div>
      </div>
      <div className={classes.skillSubTitle}>CMS</div>
      <div className={classes.skillBox}>
        <div className={classes.skillItem}>
          <FaWordpress />
          <p>Wordpress</p>
        </div>
      </div>
      <div className={classes.skillSubTitle}>OTHERS</div>
      <div className={classes.skillBox}>
        <div className={classes.skillItem}>
          <SiHeroku />
          <p>Heroku</p>
        </div>
        <div className={classes.skillItem}>
          <SiFirebase />
          <p>Firebase</p>
        </div>
        <div className={classes.skillItem}>
          <AiOutlineGithub />
          <p>Github</p>
        </div>
        <div className={classes.skillItem}>
          <SiGit />
          <p>Git</p>
        </div>
        <div className={classes.skillItem}>
          <SiBitbucket />
          <p>Bitbucket</p>
        </div>
      </div>
      <div className={classes.skillSubTitle}>BONUS</div>
      <div className={classes.skillBox}>
        <div className={classes.skillItem}>
          <SiEspressif />
          <p>
            ESP series
            <br />
            by Espressif
          </p>
        </div>
        <div className={classes.skillItem}>
          <SiArduino />
          <p>Arduino</p>
        </div>
        <div className={classes.skillItem}>
          <BiMicrochip />
          <p>PIC and so on</p>
        </div>
        <div className={classes.skillItem}>
          <GiSolderingIron />
          <p>Hardware</p>
        </div>
        <div className={classes.skillItem}>
          <SiLinux />
          <p>Linux</p>
        </div>
      </div>
    </div>
  );
};

export default SkillBox;
