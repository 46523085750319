import { AiOutlineGithub } from "react-icons/ai";
import { CgWebsite } from "react-icons/cg";

import classes from "./AppDetails.module.css";
import timetrackerImage from "../assets/images/timetracker.png";

const TimeTracker = () => {
  window.scrollTo(0, 0);

  return (
    <>
      <div className={classes.projectTitle}>TimeTracker</div>
      <div className={classes.projectContainer}>
        <div className={classes.projectImageBox}>
          <img
            className={classes.projectImage}
            src={timetrackerImage}
            alt='timertracker image'
          />
        </div>
        <div className={classes.projectURL}>
          <a
            href='https://github.com/yoshino9397/TimeTracker-App'
            target='_blank'
            rel='noopener noreferrer'
          >
            CODE <AiOutlineGithub />
          </a>
          <a
            href='https://calm-journey-01741.herokuapp.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            WEBSITE <CgWebsite />
          </a>
        </div>
        <div className={classes.where}>
          <div className={classes.whereTitle}>
            Where is the most striking part of this app?
          </div>
          <p className={classes.whereDescription}>
            This is the point at which working time can be logged using the
            Pomodoro technique.
          </p>
        </div>
        <div className={classes.why}>
          <div className={classes.whyTitle}>Why did I make this app?</div>
          <p className={classes.whyDescription}>
            Are you familiar with the Pomodoro technique? I am apt to focus on
            efficiency and I created this application with one of my classmates
            because I could not find an application like this. I could find a
            working time logger and a Pomodoro timer, but they were separated. I
            made "Login", "Register" and "Timer" pages.
          </p>
        </div>
        <div className={classes.who}>
          <div className={classes.whoTitle}>
            Who are the targets of this app?
          </div>
          <p className={classes.whoDescription}>
            The targets of this application are business people who need to log
            their own working time.
          </p>
        </div>
        <div className={classes.what}>
          <div className={classes.whatTitle}>
            What is the thing of this website that I struggled with the most?
          </div>
          <p className={classes.whatDescription}>
            A lot. On the "Login" and "Register" pages, I wanted to make an
            input form like a moving label. Moving the label in itself was easy,
            but I had to consider "data cache". If the user stored the data on
            the browser, the input form already retained the data at the time
            the user accessed the page. In other words, no event occurs in
            JavaScript at that time. So I had to check the CSS class name of
            each browser at that time.
          </p>
          <p className={classes.whatDescription}>
            The timer did not work as accurately as Hardware's crystal if I just
            wrote it in the usual way, so I had a hard time in this area as
            well.
          </p>
          <p className={classes.whatDescription}>
            My classmate Yoshino was in charge of the menu bar and Dashboard,
            which was a big help.
          </p>
        </div>
      </div>
    </>
  );
};

export default TimeTracker;
