import { CgWebsite } from "react-icons/cg";

import classes from "./AppDetails.module.css";
import CoeusImage from "../assets/images/coeus.png";

const Coeus = () => {
  window.scrollTo(0, 0);

  return (
    <>
      <div className={classes.projectTitle}>COEUS</div>
      <div className={classes.projectContainer}>
        <div className={classes.projectImageBox}>
          <img
            className={classes.projectImage}
            src={CoeusImage}
            alt='coeus image'
          />
        </div>
        <div className={classes.projectURL}>
          <a
            href='http://www.coeustechs.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            WEBSITE <CgWebsite />
          </a>
        </div>
        <div className={classes.where}>
          <div className={classes.whereTitle}>
            Where is the most striking part of this app?
          </div>
          <p className={classes.whereDescription}>
            I made "TEAM" and "CONTACT" pages. As you can see, "TEAM" page uses
            animation. This animation was made by "Haiku Animator". I used it
            for the first time. I tried to use CSS animation at first, but I
            found it difficult when I considered responsive design. So I chose
            "Haiku Animator" and made it.
          </p>
        </div>
        <div className={classes.why}>
          <div className={classes.whyTitle}>Why did I make this app?</div>
          <p className={classes.whyDescription}>
            I made this application as a job.
          </p>
        </div>
        <div className={classes.who}>
          <div className={classes.whoTitle}>
            Who are the targets of this app?
          </div>
          <p className={classes.whoDescription}>
            The targets are people who play tennis.
          </p>
        </div>
        <div className={classes.what}>
          <div className={classes.whatTitle}>
            What is the thing of this website that I struggled with the most?
          </div>
          <p className={classes.whatDescription}>
            This site itself is simple, so it is animation I struggled with.
          </p>
        </div>
      </div>
    </>
  );
};

export default Coeus;
