import classes from "./Hero.module.css";
import heroImage from "../../assets/images/IMG_0166.PNG";

const Hero = ({ afterAnime }) => {
  return (
    <div
      className={`${classes.heroContainer} ${
        !afterAnime ? classes.heroAnime : classes.heroAfterAnime
      }`}
    >
      <img className={classes.heroImage} src={heroImage} alt='hero image' />
      <div className={classes.heroMessage}>
        <h2>A Front-end Developer based in Vancouver</h2>
        <p>
          A front-end developer who loves building creative web applications that make life easier. Have developed several websites and applications.
        </p>
      </div>
    </div>
  );
};

export default Hero;
