import { AiOutlineGithub } from "react-icons/ai";
import { CgWebsite } from "react-icons/cg";

import classes from "./AppDetails.module.css";
import HodieImage from "../assets/images/hodie.png";

const Hodie = () => {
  window.scrollTo(0, 0);

  return (
    <>
      <div className={classes.projectTitle}>HODIE (an e-commerce site)</div>
      <div className={classes.projectContainer}>
        <div className={classes.projectImageBox}>
          <img
            className={classes.projectImage}
            src={HodieImage}
            alt='hodie image'
          />
        </div>
        <div className={classes.projectURL}>
          <a
            href='https://github.com/k-e-r/HODIE'
            target='_blank'
            rel='noopener noreferrer'
          >
            CODE <AiOutlineGithub />
          </a>
          <a
            href='https://www.kestudies.com/st/hodie/ecomm/'
            target='_blank'
            rel='noopener noreferrer'
          >
            WEBSITE <CgWebsite />
          </a>
        </div>
        <div className={classes.where}>
          <div className={classes.whereTitle}>
            Where is the most striking part of this app?
          </div>
          <p className={classes.whereDescription}>
            I focused on the design of this application. I imagined this website
            is for fashion brands for 30s - 40s' women. Users can purchase
            clothes which were in the brand's magazine on this website. "HODIE"
            means "TODAY" in latin. These clothes which were in the brand's
            magazine make you today. It is a catch copy of this brand.
          </p>
        </div>
        <div className={classes.why}>
          <div className={classes.whyTitle}>Why did I make this app?</div>
          <p className={classes.whyDescription}>
            I made this application as one of the school's assignments. I had to
            make an e-commerce site.
          </p>
        </div>
        <div className={classes.who}>
          <div className={classes.whoTitle}>
            Who are the targets of this app?
          </div>
          <p className={classes.whoDescription}>
            The targets are women in their 30s - 40s'. Because they are the
            targets of this brand.
          </p>
        </div>
        <div className={classes.what}>
          <div className={classes.whatTitle}>
            What is the thing of this website that I struggled with the most?
          </div>
          <p className={classes.whatDescription}>
            The website itself is simple enough to test the cart. The most time
            consuming part was probably the creation of the brand setup and
            design.
          </p>
        </div>
      </div>
    </>
  );
};

export default Hodie;
