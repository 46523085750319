import { AiOutlineGithub } from "react-icons/ai";
import { CgWebsite } from "react-icons/cg";

import classes from "./AppDetails.module.css";
import autoschedulerImage from "../assets/images/autoscheduler.png";

const AutoScheduler = () => {
  window.scrollTo(0, 0);

  return (
    <>
      <div className={classes.projectTitle}>AutoScheduler</div>
      <div className={classes.projectContainer}>
        <div className={classes.projectImageBox}>
          <img
            className={classes.projectImage}
            src={autoschedulerImage}
            alt='autoscheduler image'
          />
        </div>
        <div className={classes.projectURL}>
          <a
            href='https://github.com/k-e-r/AutoScheduler'
            target='_blank'
            rel='noopener noreferrer'
          >
            CODE <AiOutlineGithub />
          </a>
          <a
            href='https://polar-lowlands-18778.herokuapp.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            WEBSITE <CgWebsite />
          </a>
        </div>
        <div className={classes.where}>
          <div className={classes.whereTitle}>
            Where is the most striking part of this app?
          </div>
          <p className={classes.whereDescription}>
            This is the point that the system automatically registers a review
            schedule at the best time for review according to the forgetting
            curve.
          </p>
        </div>
        <div className={classes.why}>
          <div className={classes.whyTitle}>Why did I make this app?</div>
          <p className={classes.whyDescription}>
            Are you familiar with the forgetting curve? I am apt to focus on
            efficiency and I created this application because I wanted to review
            English vocabulary at the best time to learn it. XD
          </p>
        </div>
        <div className={classes.who}>
          <div className={classes.whoTitle}>
            Who are the targets of this app?
          </div>
          <p className={classes.whoDescription}>
            I made this application for students like me. So the design of this
            application is a little light and pop.
          </p>
        </div>
        <div className={classes.what}>
          <div className={classes.whatTitle}>
            What is the thing of this website that I struggled with the most?
          </div>
          <p className={classes.whatDescription}>
            This is the first time I tried to make a calendar app without using
            any libraries of a calendar. I always use calendar applications such
            as Google Calendar. I did not think it was easy, but I also did not
            think it was too difficult before I tried to make this web
            application. It was more difficult than I thought. Mainly due to the
            lack of functional design before the application was created. As
            hard as it was, it was an exciting experience for me.
          </p>
          <p className={classes.whatDescription}>
            The main difficulties were that I showed the dates of the previous
            and next months in gray and that I had to take into account the time
            difference when registering the schedule on the server.
          </p>
          <p className={classes.whatDescription}>
            By the way, the date is calculated with new Date().
          </p>
        </div>
      </div>
    </>
  );
};

export default AutoScheduler;
