import { Outlet, Link } from "react-router-dom";
import classes from "./Header.module.css";
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Header = ({ afterAnime }) => {
  return (
    <>
      <header
        className={`${classes.headerContainer} ${
          !afterAnime ? classes.headerAnime : ""
        }`}
      >
        <div className={classes.headerLeftBox}>
          <AnchorLink className={classes.headerName} href="#root">
            KAORI ERA
          </AnchorLink>
          <AnchorLink className={classes.headerSkills} href="#skills" offset="40">SKILLS</AnchorLink>
          <AnchorLink className={classes.headerProjects} href="#projects" offset="60">PROJECTS</AnchorLink>
        </div>

        <a
          href='../../assets/KaoriEraResume.pdf'
          target='_blank'
          rel='noopener noreferrer'
          className={classes.headerResume}
        >
          RESUME
        </a>
      </header>
      <Outlet />
    </>
  );
};

export default Header;
