import { useState } from "react";
import { Routes, Route } from "react-router-dom";

import Animation from "./components/animation/Animation";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Hero from "./components/hero/Hero";
import Projects from "./components/projects/Projects";
import SkillBox from "./components/skillBox/SkillBox";
import AutoScheduler from "./pages/AutoScheduler";
import BookInfo from "./pages/BookInfo";
import Coeus from "./pages/Coeus";
import Hodie from "./pages/Hodie";

import NewsTagApp from "./pages/NewsTagApp";
import TimeTracker from "./pages/TimeTracker";

function App() {
  const [afterAnime, setAfterAnime] = useState(sessionStorage.getItem("anime"));

  setTimeout(() => {
    if (!afterAnime) {
      sessionStorage.setItem("anime", true);
      setAfterAnime(true);
    }
  }, 5300);

  return (
    <>
      {!afterAnime && <Animation />}
      <Routes>
        <Route path='/' element={<Header afterAnime={afterAnime} />}>
          <Route path='newstagapp' element={<NewsTagApp />} />
          <Route path='autoscheduler' element={<AutoScheduler />} />
          <Route path='timetracker' element={<TimeTracker />} />
          <Route path='hodie' element={<Hodie />} />
          <Route path='coeus' element={<Coeus />} />
          <Route path='bookinfo' element={<BookInfo />} />
          <Route
            path=''
            element={
              <>
                <Hero afterAnime={afterAnime} />
                {afterAnime && (
                  <>
                    <SkillBox />
                    <Projects />
                  </>
                )}
              </>
            }
          ></Route>
        </Route>
      </Routes>
      {afterAnime && <Contact />}
      {afterAnime && <Footer />}
    </>
  );
}

export default App;
