import { AiOutlineGithub } from "react-icons/ai";
import { CgWebsite } from "react-icons/cg";

import classes from "./AppDetails.module.css";
import bookinfoImage from "../assets/images/bookinfo.png";

const BookInfo = () => {
  window.scrollTo(0, 0);

  return (
    <>
      <div className={classes.projectTitle}>BookInfo</div>
      <div className={classes.projectContainer}>
        <div className={classes.projectImageBox}>
          <img
            className={classes.projectImage}
            src={bookinfoImage}
            alt='bookinfo image'
          />
        </div>
        <div className={classes.projectURL}>
          <a
            href='https://github.com/k-e-r/MidTermProject-HTML'
            target='_blank'
            rel='noopener noreferrer'
          >
            CODE <AiOutlineGithub />
          </a>
          <a
            href='https://www.kestudies.com/st/bookinfo/index.html'
            target='_blank'
            rel='noopener noreferrer'
          >
            WEBSITE <CgWebsite />
          </a>
        </div>
        <div className={classes.where}>
          <div className={classes.whereTitle}>
            Where is the most striking part of this app?
          </div>
          <p className={classes.whereDescription}>
            You can search without clicking Enter. Other functions are not
            special. In other words, they are familiar. Anyone can use this
            website without explanation.
          </p>
        </div>
        <div className={classes.why}>
          <div className={classes.whyTitle}>Why did I make this app?</div>
          <p className={classes.whyDescription}>
            I made this application as one of the school's assignments. Because
            the content changes dynamically, the URL before and after the search
            does not change.
          </p>
        </div>
        <div className={classes.who}>
          <div className={classes.whoTitle}>
            Who are the targets of this app?
          </div>
          <p className={classes.whoDescription}>
            The targets of this application are people who use online shops when
            they purchase comics, videos and games.
          </p>
        </div>
        <div className={classes.what}>
          <div className={classes.whatTitle}>
            What is the thing of this website that I struggled with the most?
          </div>
          <p className={classes.whatDescription}>
            I struggled to create a site design that was familiar, yet not
            identical.
          </p>
        </div>
      </div>
    </>
  );
};

export default BookInfo;
